import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, Spacer } from "components"
import * as theme from "theme"

const Highlights = ({ title, highlights }) => {
  return (
    <Container>
      <Spacer pt={{ xs: 20 }} pb={{ xs: 40, sm: 100 }}>
        <Edges size="md">
          <Spacer mb={20}>
            <Typography
              variant="h2"
              children={`${title} highlights`}
              color="inherit"
            />
          </Spacer>

          <Grid>
            {highlights &&
              highlights.map((o, i) => {
                return (
                  <Highlight key={i}>
                    <HighlightTop>
                      {o.value && <Value variant="h3" children={o.value} />}
                      {o.unit && <Unit variant="body2" children={o.unit} />}
                    </HighlightTop>
                    {o.label && <Label children={o.label} />}
                  </Highlight>
                )
              })}
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.primary};
  color: ${theme.colors.text.light};
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Highlight = styled.div`
  position: relative;
  margin-bottom: 20px;
  flex-basis: 50%;

  @media (min-width: 900px) {
    padding: 0 40px;
    flex-basis: auto;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background: #fff;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &:after {
        content: none;
      }
    }
  }
`

const HighlightTop = styled.div`
  display: flex;
  align-items: flex-end;
`

const Value = styled(Typography)`
  && {
    color: ${theme.colors.tertiary};
    font-size: 50px;
    line-height: 50px;
    font-weight: 400;
    margin: 0;
  }
`

const Unit = styled(Typography)`
  && {
    color: ${theme.colors.tertiary};
    margin-left: 10px;
    margin-bottom: 2px;
  }
`

const Label = styled(Typography)`
  && {
    margin-top: 4px;
    text-transform: uppercase;
  }
`

export default Highlights
