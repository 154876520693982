import React from "react"
import styled from "styled-components"
import { IconButton } from "@material-ui/core"

// import app components
import { Edges, Spacer, BackgroundImage } from "components"
import { useStore } from "store"
import WaveIcon from "icons/wave-2.svg"
import PlayIcon from "icons/video-play.svg"

const VideoGallery = ({ videos }) => {
  const [, dispatch] = useStore()

  const handleOpenLightbox = url => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        slide: 0,
        open: true,
        slides: [{ link: { url } }]
      }
    })
  }

  return (
    <Container>
      <WaveContainer>
        <WaveIcon />
      </WaveContainer>

      <Spacer mb={{ xs: 100, sm: 150, md: 250 }}>
        <GalleryContainer>
          <Spacer pt={{ xs: 80, md: 180 }} pb={{ xs: 180 }}>
            <Edges size="ml">
              <GalleryFirstImage
                hasVideo={!!videos[0].youtubeUrl}
                onClick={() =>
                  !!videos[0].youtubeUrl &&
                  handleOpenLightbox(videos[0].youtubeUrl)
                }
              >
                <BackgroundImage image={videos[0].image} />
                {videos[0].youtubeUrl && (
                  <PlayIconButton>
                    <PlayIcon />
                  </PlayIconButton>
                )}
              </GalleryFirstImage>
            </Edges>
          </Spacer>

          <GallerySecondImage
            hasVideo={!!videos[1].youtubeUrl}
            onClick={() =>
              !!videos[1].youtubeUrl && handleOpenLightbox(videos[1].youtubeUrl)
            }
          >
            <BackgroundImage image={videos[1].image} />
            {videos[1].youtubeUrl && (
              <PlayIconButton>
                <PlayIcon />
              </PlayIconButton>
            )}
          </GallerySecondImage>

          <GalleryThirdImage
            hasVideo={!!videos[2].youtubeUrl}
            onClick={() =>
              !!videos[2].youtubeUrl && handleOpenLightbox(videos[2].youtubeUrl)
            }
          >
            <BackgroundImage image={videos[2].image} />
            {videos[2].youtubeUrl && (
              <PlayIconButton>
                <PlayIcon />
              </PlayIconButton>
            )}
          </GalleryThirdImage>
        </GalleryContainer>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const GalleryContainer = styled.div`
  position: relative;
  width: 100%;
  background: radial-gradient(
    69.12% 69.29% at 12.66% -27.53%,
    #000000 41.89%,
    #252525 100%
  );
`

const WaveContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-62px);

  @media (min-width: 960px) {
    width: 50%;
  }

  svg {
    max-width: 100%;
    width: auto;
    height: 150px;
  }
`

const GalleryFirstImage = styled.div`
  position: relative;
  max-width: 620px;
  width: 100%;
  height: 350px;
  margin: 0 auto;
`

const GallerySecondImage = styled.div`
  position: absolute;
  width: 40%;
  right: 55%;
  top: 100%;
  height: 130px;
  transform: translateY(-100px);

  @media (min-width: 900px) {
    right: 50%;
    width: 340px;
    height: 180px;
    transform: translate(-80px, -100px);
  }
`

const GalleryThirdImage = styled.div`
  position: absolute;
  width: 40%;
  left: 55%;
  top: 100%;
  height: 180px;
  transform: translateY(-100px);

  @media (min-width: 900px) {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 400px;
    height: 230px;
    transform: translate(40px, -100px);
  }
`

const PlayIconButton = styled(IconButton)`
  && {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 220px !important;
      height: 144px !important;
    }
  }
`

export default VideoGallery
