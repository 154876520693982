import React from "react"
import styled from "styled-components"

// import app components
import { Edges, Spacer, Textarea } from "components"
import * as theme from "theme"

const Introduction = ({ text }) => {
  return (
    <Container>
      <Edges size="md">
        <Spacer pt={{ xs: 40 }} pb={{ xs: 20, sm: 60 }}>
          <IntroductionContent>
            <Textarea content={text} overrides={{ p: "body1" }} />
          </IntroductionContent>
        </Spacer>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.primary};
`

const IntroductionContent = styled.div`
  max-width: 620px;
  color: #fff;
`

export default Introduction
