import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"
import { Typography } from "@material-ui/core"

// import app components
import { Button, Spacer } from "components"
import * as theme from "theme"

const Download = ({ file }) => {
  return (
    <Container>
      {file?.title && (
        <Spacer pr={20} mt={{ xs: 20, sm: 0 }} mb={{ xs: 10, sm: 0 }}>
          <Typography children={Parser(file.title || "")} variant="body2" />
        </Spacer>
      )}
      {(file?.localFile?.publicURL && (
        <ButtonContainer href={file?.localFile?.publicURL} target="_blank">
          <Button children="Download" variant="outlined" color="primary" />
        </ButtonContainer>
      )) ||
        (file?.mediaItemUrl && (
          <ButtonContainer href={file?.mediaItemUrl} target="_blank">
            <Button children="Download" variant="outlined" color="primary" />
          </ButtonContainer>
        ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: ${theme.breakpoints.up.sm}) {
    flex-wrap: nowrap;
  }
`

const ButtonContainer = styled.a`
  width: 100%;

  @media (min-width: ${theme.breakpoints.up.sm}) {
    width: auto;
  }
`

export default Download
