import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"
import { Typography } from "@material-ui/core"

// import app components
import { Spacer } from "components"

const Specification = ({ name, title, value }) => {
  const labels = {
    beam: "Beam",
    boatWeight: "Boat Weight",
    wetWeight: "Est. Wet Weight",
    chambers: "Chambers",
    internalBeam: "Internal Beam",
    internalLength: "Internal Length",
    maxLoad: "Max Load",
    loa: "LOA",
    maxPassengers: "Max Passengers",
    maxPower: "Max Power",
    maxRecommendedSpeed: "Max Recommended Speed",
    recommendedPower: "Recommended Power",
    tankSize: "Tank Size",
    tubeDiameter: "Tube Diameter",
    tubeMaterial: "Tube Material"
  }
 
  return (
    <Container>
      {name && value && (
        <Spacer pr={20}>
          <Typography children={Parser(labels[name] || "")} variant="body2" />
        </Spacer>
      )}
      {title && (
        <Spacer pr={20}>
          <Typography children={Parser(title)} variant="body2" />
        </Spacer>
      )}
      {value && <Value children={Parser(value || "")} variant="body2" />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`

const Value = styled(Typography)`
  width: 50%;
  text-align: right;
`

export default Specification
