import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Spacer, BackgroundImage } from "components"

const ColorOptions = ({ image, title }) => {
  return (
    <Container>
      <ImageContainer>
        <BackgroundImage image={image} />
      </ImageContainer>

      <Content>
        <Spacer pr={20}>
          <Typography variant="h5" children={title} />
        </Spacer>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const ImageContainer = styled.div`
  position: relative;
  height: 37px;
  width: 37px;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
`

export default ColorOptions
