import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Drawer, Typography } from "@material-ui/core"

// import app components
import {
  Accordion,
  Boxes,
  Button,
  Hero,
  Gallery,
  Reviews,
  TextImage
} from "components"

import Introduction from "components/boat/Introduction"
import ColorOptions from "components/boat/ColorOptions"
import Download from "components/boat/Download"
import VideoGallery from "components/boat/VideoGallery"
import Highlights from "components/boat/Highlights"
import Specification from "components/boat/Specification"
import PartAssessories from "components/boat/PartAssessories"

import { Spacer, Edges, Textarea } from "components"
import { getIcon } from "utils"
import { colors } from "theme"

const BoatSingle = props => {
  const {
    data: {
      boat: {
        title,
        acf: {
          introduction,
          highlights,
          content,
          videos,
          additionalInformation,
          additionalAccordionItems,
          gallery,
          standardFeatures,
          optionalFeatures,
          colorOptions,
          manuals,
          partsAccessories,
          specifications: { modelSpecificFeatures, ...otherSpecs },
          reviews
        }
        // boatfamilies
      }
    }
  } = props

  const [drawerState, setDrawerState] = useState(false)

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerState(open)
  }

  const accordionItems = []

  if (standardFeatures) {
    accordionItems.push({
      title: "Standard Features",
      content: <Textarea content={standardFeatures} />
    })
  }

  if (optionalFeatures) {
    accordionItems.push({
      title: "Optional Features",
      content: <Textarea content={optionalFeatures} />
    })
  }

  const specs =
    otherSpecs &&
    Object.keys(otherSpecs).map((key, i) => (
      <Specification key={i} name={key} value={otherSpecs[key]} />
    ))

  if (modelSpecificFeatures && modelSpecificFeatures.length > 0) {
    specs.push(
      <Spacer mt={30} mb={10}>
        <Typography variant="h6" children={`Model specific features`} />
      </Spacer>
    )

    specs.push(
      modelSpecificFeatures.map((o, i) => (
        <Specification key={i} title={o.title} value={o.value} />
      ))
    )
  }

  accordionItems.push({
    title: "Specifications",
    content: specs
  })

  if (manuals && manuals.length > 0) {
    accordionItems.push({
      title: "Manuals & Brochures",
      content: manuals.map((o, i) => <Download key={i} {...o} />)
    })
  }

  if (partsAccessories?.image) {
    accordionItems.push({
      title: "Parts & Accessories",
      content: <PartAssessories image={partsAccessories.image} />
    })
  }

  if (colorOptions && colorOptions.length > 0) {
    accordionItems.push({
      title: "Accent Color Options",
      content: colorOptions.map((o, i) => (
        <ColorOptions key={i} title={o.color} image={o.image} />
      ))
    })
  }

  if (additionalAccordionItems && additionalAccordionItems.length > 0) {
    additionalAccordionItems.map((o, i) =>
      accordionItems.push({
        title: o.title,
        content: <Textarea key={i} content={o.content} />
      })
    )
  }

  return (
    <>
      <Hero headline={title} image={introduction.image} border />

      {introduction?.text && <Introduction {...introduction} />}

      {highlights && <Highlights title={title} highlights={highlights} />}

      {content &&
        content.map((o, i) => {
          return <TextImage key={i} {...o} />
        })}

      <VideoGallery videos={videos} />

      <Spacer mb={{ xs: 40 }}>
        <Edges size="sm" style={{ maxWidth: "640px" }}>
          <Accordion
            headline={`${title} Features & Specifications`}
            items={accordionItems}
          />
        </Edges>
      </Spacer>

      {!!reviews && (
        <Edges size="cont">
          <ReviewsCont>
            <Button
              role="presentation"
              children={"Click for reviews"}
              onClick={toggleDrawer(true)}
              onKeyDown={toggleDrawer(false)}
              variant="outlinedPrimary"
            />
            <StyledDrawer
              anchor="bottom"
              open={drawerState}
              onClose={toggleDrawer(false)}
            >
              <ChevronClose onClick={toggleDrawer(false)}>
                {getIcon("ChevronDown")}
              </ChevronClose>
              <Reviews reviews={reviews} />
              <Spacer mb={30} />
              <FlexCenter>
                <Button
                  to={"/news"}
                  children={"See all Walker Bay reviews"}
                  variant="outlinedPrimary"
                />
              </FlexCenter>
            </StyledDrawer>
          </ReviewsCont>
        </Edges>
      )}

      <Boxes {...additionalInformation} noneClickable="true" />

      <Gallery images={gallery} />
    </>
  )
}

const ChevronClose = styled.button`
  background: transparent;
  border: 0px;
  color: ${colors.tertiary};

  &:active,
  &:focus {
    box-shadow: inset 0px 0px 0px 1px ${colors.tertiary};
    outline: none;
  }
`

const ReviewsCont = styled.div`
  text-align: center;
`
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorBottom {
    background: #252525;
    padding: 15px 0px 30px;
  }
`

export const CollectionQuery = graphql`query DefaultSingleBoat($id: String!) {
  boat: wpBoat(id: {eq: $id}) {
    id
    title
    uri
    acf {
      introduction {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      highlights {
        label
        value
        unit
      }
      content {
        background
        button {
          target
          title
          url
        }
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
        imageAlignment
        text
        youtubeUrl
      }
      videos {
        youtubeUrl
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      standardFeatures
      optionalFeatures
      colorOptions {
        color
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
      manuals {
        file {
          title
          localFile {
            publicURL
          }
          mediaItemUrl
        }
      }
      partsAccessories {
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
      specifications {
        loa
        beam
        tubeDiameter
        internalLength
        internalBeam
        chambers
        maxPassengers
        maxLoad
        boatWeight
        wetWeight
        maxPower
        recommendedPower
        maxRecommendedSpeed
        tankSize
        tubeMaterial
        modelSpecificFeatures {
          title
          value
        }
      }
      reviews {
        id
        termTaxonomyId
        contentNodes {
          nodes {
            id
            uri
          }
        }
      }
      additionalAccordionItems {
        title
        content
      }
      additionalInformation {
        headline
        text
        boxes {
          headline
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
          }
          text
        }
      }
      gallery {
        layout
        image1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
        image2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
        image3 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    boatfamilies {
      nodes {
        id
        name
      }
    }
  }
}
`

export default BoatSingle
