import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"
import { Typography } from "@material-ui/core"

// import app components
import { Button, Spacer, BackgroundImage } from "components"

const PartAssessories = ({ image }) => {
  return (
    <Container>
      <ImageContainer>
        <BackgroundImage image={image} />
      </ImageContainer>

      <Content>
        <Spacer pr={20} mb={{ xs: 10, sm: 0 }}>
          <Typography
            variant="h6"
            children={`Looking for parts? Order parts here.`}
          />
        </Spacer>
        <Button
          to={`https://shop.inflatableboatparts.com/product-category/walker-bay-boat-parts/`}
          children={"Shop Now"}
          variant="outlined"
          color="primary"
        />
      </Content>
    </Container>
  )
}

const Container = styled.div``

const ImageContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  margin-bottom: 20px;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

export default PartAssessories
